import React from 'react';
import MainBody from './body.js';
import { createBrowserHistory } from 'history';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.history = createBrowserHistory();
    }

    componentDidMount(){
        const history = this.history;
        if (history.location?.state) {
            let state = { ...history.location.state };
            delete state.prevPath;
            delete state.backPage;
            history.replace({ ...history.location, state });
        }
    }

    render(){
        return(
            <div className="container-fluid home-page page">
                <div className="row">
                    <MainBody state={this.state} history={this.history}></MainBody>
                </div>
            </div>

        );
    }
}

export default HomePage;
