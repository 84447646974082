import React from 'react';
import Software from '../../../assets/softDevPage/software.png';
import Mobile from '../../../assets/softDevPage/mobile.png';
import Serverless from '../../../assets/softDevPage/serverless.png';
import Custom from '../../../assets/softDevPage/custom.png';
import './index.scss';

function CapabilitiesSection(props) {

    const { isMobile, bootstrapLarge } = props.state; 
    const cardImgs = [Software, Mobile, Serverless, Custom];
    const cards=[];
    const cardObjs=[
        {title:'SOFTWARE APPLICATIONS', spacerLine:true},
        {title:'MOBILE APPLICATIONS', spacerLine:true},
        {title:'SERVERLESS TECHNOLOGIES', spacerLine:true},
        {title:'CUSTOM SOLUTIONS', spacerLine:false}
    ];
    const mobileCardView = (index, cardObj) => {
        return(
            <div className="row w-75 mx-auto" key={index}>
                <div className="col-6 text-center leftLine">
                    <img src={cardImgs[index]} alt=""/>
                </div>
                <div className="col-6 my-auto">
                    <div className="text-center">
                        <h6><strong>{cardObj.title}</strong></h6>
                    </div>
                </div>
            </div>
        );
    }
    const desktopCardView = (index, cardObj) => {
        const spacerLine = bootstrapLarge && cardObj.spacerLine;
        return(
            <div className={spacerLine ? 'col-sm-6 col-lg-3 spacerLine' : 'col-sm-6 col-lg-3'} 
                 key={index}>
                <div className="card h-100">
                    <img className="card-img-top mx-auto" src={cardImgs[index]} alt=""/>
                    <div className="card-body">
                        <h5 className="card-title"><strong>{cardObj.title}</strong></h5>
                    </div>
                </div>
            </div>
        );
    }

    for (const [index, cardObj] of cardObjs.entries()) {
        cards.push(
            isMobile ? mobileCardView(index, cardObj) : desktopCardView(index, cardObj)
        );
    }

    return(
        <div className='capabilities-section container-fluid text-center'>
            <div className="px-2">
                <h3><strong>OUR CAPABILITIES</strong></h3>
            </div>
            <div className={ isMobile ? 'row mx-auto mt-2' : 'row mx-auto mt-5' }>
                {cards}
            </div>
        </div>
    );
}

export default CapabilitiesSection;