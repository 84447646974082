import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import MainSection from './mainSection';
import CapabilitiesSection from './capabilitiesSection';
import ProcessSection from './processSection';
import StartSection from '../../common/startSection';
import Footer from '../../common/footer';
import Nav from '../../common/navbar';


function SoftDevPage(props) {

    React.useEffect(() => {
        if (props.history.action === "POP") {
            let state = {prevPath: "soft-dev-page", backPage: true} ;
            props.history.replace({ ...props.history.location, state });
        }
    }, [props])

    return(
        <div className="soft-dev-page page">
            <Nav />
            <div className="page-wrapper">
                <div className="page-back">
                    <div className="back-arrow"></div>
                    <Link to={{ pathname: "/", state: {prevPath: "soft-dev-page", backPage: true} }}>
                        <div className="back-button">BACK</div>
                    </Link>
                </div>
                <div className="page-content">
                    <MainSection />
                    <CapabilitiesSection state={props.state}/>
                    <ProcessSection />
                    <StartSection />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SoftDevPage;
