import React from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import ContactUsModal from '../contactUs/contactUsModal';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';

function Nav() {

    const [contactUsShow, setContactUsShow] = React.useState(false);

    return(
        <nav className="navbar navbar-expand-md navbar-light bg-light">
            <div className="logo-block"><img src={logo} /></div>
            <a className="navbar-brand ml-3 mr-auto" href="/">Evervice</a>
            <button className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item pr-4">
                        <Link className="nav-link" to={{ pathname: "/website-creation", state: {prevPath: "home-page"}}}>Website Creation</Link>
                    </li>
                    <li className="nav-item pr-4">
                        <Link className="nav-link" to={{ pathname: "/software-development", state: {prevPath: "home-page"}}}>Software Development</Link>
                    </li>
                    <li className="nav-item pr-4">
                        <button className="nav-link contact-button w-100"
                                onClick={() => setContactUsShow(true)}>
                                Get Started
                        </button>
                    </li>
                </ul>
            </div>
            <ContactUsModal
                show={contactUsShow}
                onHide={() => setContactUsShow(false)} />
        </nav>
    );
}

export default Nav;
