import React from 'react';
import Responsive from '../../../assets/webCreatePage/responsive.png';
import Analytics from '../../../assets/webCreatePage/analytics.png';
import Customizable from '../../../assets/webCreatePage/customizable.png';
import './index.scss';

function CapabilitiesSection(props) {

    const { isMobile } = props.state; 
    const cardImgs = [Responsive, Analytics, Customizable];
    const cards=[];
    const cardObjs=[
        {title:'RESPONSIVE', text:'So your website will look beautiful across all devices.', spacerLine:true},
        {title:'ANALYTICS', text:'See how your website is performing with real-time feedback.', spacerLine:true},
        {title: 'CUSTOMIZABLE', text:'Build exactly what you need- shopping functions, galleries, and more.', spacerLine:false},
    ];
    const mobileCardView = (index, cardObj) => {
        return(
            <div className="row w-75 mx-auto" key={index}>
                <div className="col-6 text-center leftLine">
                    <img src={cardImgs[index]} alt=""/>
                </div>
                <div className="col-6 my-auto">
                    <div className="text-center">
                        <h6><strong>{cardObj.title}</strong></h6>
                        <p>{cardObj.text}</p>
                    </div>
                </div>
            </div>
        );
    }
    const desktopCardView = (index, cardObj) => {
        const spacerLine = cardObj.spacerLine;
        return(
            <div className={spacerLine ? 'col-sm-4 spacerLine' : 'col-sm-4'} 
                 key={index}>
                <div className="card h-100">
                    <img className="card-img-top mx-auto" src={cardImgs[index]} alt=""/>
                    <div className="card-body">
                        <h5 className="card-title"><strong>{cardObj.title}</strong></h5>
                        <p>{cardObj.text}</p>
                    </div>
                </div>
            </div>
        );
    }

    for (const [index, cardObj] of cardObjs.entries()) {
        cards.push(
            isMobile ? mobileCardView(index, cardObj) : desktopCardView(index, cardObj)
        );
    }

    return(
        <div className='capabilities-section container-fluid text-center'>
            <div className="px-2">
                <h3><strong>OUR CAPABILITIES</strong></h3>
                <p>Whether you're building a portfolio or need a website for your business, we're here to help.</p>
            </div>
            <div className={ isMobile ? 'row mx-auto mt-2' : 'row mx-auto mt-5' }>
                {cards}
            </div>
        </div>
    );
}

export default CapabilitiesSection;