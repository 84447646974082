import React from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ContactUsModal from '../contactUs/contactUsModal';


function MainFooter() {
  const usedSocialMedias = ['Facebook','Instagram','LinkedIn'];
  const socialMediaBlocks = SocialMedia(usedSocialMedias);
  const [contactUsShow, setContactUsShow] = React.useState(false);

  return (
    <div className="main-footer">
      <div className="company-name">
        Evervice LLC
      </div>
      <div className="social-media-links" style={{display: "inline", verticalAlign: "middle"}} >
      </div>
      <div className="footer-contact">
        <button className="contact-button"
                onClick={() => setContactUsShow(true)}>
                Get Started
        </button>
      </div>
      <ContactUsModal
              show={contactUsShow}
              onHide={() => setContactUsShow(false)} />
    </div>
  );
}

function SocialMedia(usedSocialMedia) {
  const socialMediaItems = [];
  for (var itr = 0; itr < usedSocialMedia.length; itr++) {
    if (usedSocialMedia[itr] === "Facebook") {
      socialMediaItems.push(
        <li key="Facebook" className="social-media">
        <a href="www.facebook.com">
        <FontAwesomeIcon icon={faFacebook} size="lg"/>
        </a></li>
      );
    }
    if (usedSocialMedia[itr] === "Instagram") {
      socialMediaItems.push(<li key="Instagram" className="social-media">

      <a href="www.instagram.com">
      <FontAwesomeIcon icon={faInstagram} size="lg"/>
      </a></li>);
    }
    if (usedSocialMedia[itr] === "LinkedIn") {
      socialMediaItems.push(<li key="LinkedIn" className="social-media">
      <a href="www.Linkedin.com">
      <FontAwesomeIcon icon={faLinkedin} size="lg"/>
      </a></li>);
    }
    if (usedSocialMedia[itr] === "Twitter") {
      socialMediaItems.push(<li key="Twitter" className="social-media">
      <a href="www.Twitter.com">
      <FontAwesomeIcon icon={faTwitter} size="lg"/>
      </a></li>);
    }
  }

  return socialMediaItems;
}

export default MainFooter
