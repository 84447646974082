import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/functions'
import { callLimited } from '../network'

const config = {
	apiKey: 'AIzaSyDKoppAYnwjOjIn0ANRhbIXWFRwmqUbxQk',
	authDomain: 'evervice-website.firebaseapp.com',
	databaseURL: 'https://evervice-website.firebaseio.com',
	projectId: 'evervice-website',
	storageBucket: 'evervice-website.appspot.com',
	messagingSenderId: '255814836054',
	appId: '1:255814836054:web:1084431f88ecac284d40f7',
	measurementId: 'G-QV85FHM30B'
}

class Firebase {
	constructor() {
		var app = firebase.initializeApp(config, 'evervice-app')
		app.analytics()

		this.sendContactEmail = callLimited(app.functions('us-central1').httpsCallable('sendContactEmail'), { maxCount: 5, throttle: 50 })
		console.log(`Initialized Firebase ${app.name}`)

		if (process.env.NODE_ENV === 'development') {
			const emulator = process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR
			console.log(`Using emulator on ${emulator}`)
			app.functions().useFunctionsEmulator(emulator)
		}
	}
}

export default Firebase
