import React from 'react';
import './index.scss';
import MainGif from '../../../assets/softDevPage/main.gif';
import DataAnalysis from '../../../assets/softDevPage/data-analysis.png';
import Automation from '../../../assets/softDevPage/automation.png';

function MainSection() {

    const cardImgs = [DataAnalysis, Automation]
    const cards = [];
    const cardObjs = [
        {title:'DATA ANALYSIS', text:"Leverage data to learn how to better fit the needs of your consumers."},
        {title:'AUTOMATION', text:"Reduce your tedious day-to-day tasks so you can actually focus on your business."}
    ];

    for (const [index, cardObj] of cardObjs.entries()) {
        cards.push(
            <div className="card-box">
                <div className="row mx-auto py-3 my-3" key={index}>
                    <div className="col-4 text-center my-auto">
                        <img className="img-fluid" src={cardImgs[index]} alt=""/>
                    </div>
                    <div className="col-8 my-auto">
                        <div className="text-center">
                            <h5>{cardObj.title}</h5>
                            <p>{cardObj.text}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return(
        <div className="main-section">
            <div className='container-fluid text-center py-5'>
                <h1>SOFTWARE DEVELOPMENT</h1>
            </div>
            <div className='container-fluid'>
                <div className='row content-row mx-auto'>
                    <div className='col-lg my-auto'>
                        <img className="img-fluid" src={MainGif} alt=""/>
                    </div>
                    <div className='col-lg my-auto'>
                        {cards}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainSection;