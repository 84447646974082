import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './infoSection.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';


class InfoSection extends Component
{
  constructor(props)
  {
    super(props);

    this.winState = {
      isMobile: window.innerWidth < 1200
    }
  }

  render() {
    const infoText = "our services".toUpperCase();
    const infoScreenOptions = [ { link: true,
                                  pathname: "/website-creation",
                                  state: { prevPath: "home-page" },
                                  text: "WEBSITE CREATION",
                                  subText: "Let's give your business the online presence it deserves." },

                                { link: true,
                                  pathname: "/software-development",
                                  state: { prevPath: "home-page" },
                                  text: "SOFTWARE DEV",
                                  subText: "Building tools and tech to automate workflows."}
                              ];
    const infoPage = this.renderInfoScreen(infoText, infoScreenOptions);
    return (
      <div>
        { infoPage }
      </div>
    )
  }

  renderInfoScreen(text, infoScreenOptions) {
    const mobileName = this.winState.isMobile ? '-mobile' : '';
    const infoScreenText =
    <div className="info-text">
      <h2>
      { text }
      </h2>
    </div>;

    const buttons = this.createInfoButtons(infoScreenOptions);

    const infoRegion =
    <div className="container-fluid info-region">
      <div className={ 'row info-row' + mobileName }>
        <div className={ 'info-title-region' + mobileName }>
          <div className={ 'info-title' + mobileName }>
            { infoScreenText }
          </div>
        </div>
        <div className={ 'info-options-region' + mobileName }>
          <div className={ 'info-options' + mobileName}>
            { buttons }
          </div>
        </div>
      </div>
    </div>;
    return infoRegion;
  }

  normalOption(text) {
    const option = <div className="option-button">
              <h1 style={{display:"inline"}}>
              { text }
              </h1>
              <h3 style={{display:"inline"}}><FontAwesomeIcon className="option-button-icon" icon={faCaretLeft} size="lg"/></h3>
              </div>;
    return option;
  }

  mobileOption(text) {
    const option = <div className="option-button-mobile">
              <h3 style={{display:"inline"}}>
              { text }
              </h3>
              <h3 style={{display:"inline"}}><FontAwesomeIcon className="option-button-icon-mobile" icon={faCaretLeft} size="lg"/></h3>
              </div>;
    return option;
  }

  createInfoButtons(options) {
    const buttons = [];
    for (var itr = 0; itr < options.length; itr++) {

      var optionLayout = <div className={ this.winState.isMobile ? "option-section-mobile" : "option-section"}
                              id={"option-" + (itr+1) + (this.winState.isMobile ? "-mobile" : "")}>
                      { this.winState.isMobile ? this.mobileOption(options[itr].text) : this.normalOption(options[itr].text) }
                      <div className={ this.winState.isMobile ? "sub-text-mobile" : "sub-text" }>
                        <h4>{ options[itr].subText }</h4>
                      </div>
                    </div>;
      const linkOption = this.renderOptionAsLink(optionLayout, options[itr]);
      buttons.push(linkOption)
    }
    return buttons;
  }

  renderOptionAsLink(optionLayout, optionDetails) {
    return (
      optionDetails.link
      ?
      <Link
        className="link-text"
        to={{ pathname: optionDetails.pathname, state: optionDetails.state }}>
        { optionLayout }
      </Link>
      :
      optionDetails.text.toUpperCase()
    )
  }
}

export default InfoSection
