import React from 'react';
import TopRightArrow from '../../assets/common/topRightArrow.png';
import ContactUsModal from '../contactUs/contactUsModal';
import './start-section.scss';

function StartSection() {

    const [contactUsShow, setContactUsShow] = React.useState(false);

    return(
        <div className="start-section">
            <div className='container-fluid'>
                <div className='jumbotron mx-auto my-0 text-center'>
                    <div>
                        <h1>LET'S GET STARTED</h1>
                        <img 
                            className="hvr-wobble-to-top-right"
                            onClick={() => setContactUsShow(true)} 
                            src={TopRightArrow} 
                            alt='' 
                            />
                    </div>
                </div>
            </div>
            <ContactUsModal 
                show={contactUsShow}
                onHide={() => setContactUsShow(false)} />
        </div>
    );
}

export default StartSection;