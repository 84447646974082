import log from '../log'

function hash (str) {
	var hash = 0; var i; var chr
	for (i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i)
		hash = ((hash << 5) - hash) + chr
		hash |= 0 // Convert to 32bit integer
	}
	return hash
}

class CallTracker {
	constructor (apiCall, { maxCount, throttle }) {
		this.apiCall = apiCall
		this.callRecord = {}
		this.maxCount = maxCount
		this.throttle = throttle
	}
}

CallTracker._trackers = {}

function wasCalled (callTracker, paramHash) {
	return paramHash in callTracker.callRecord
}

function getRecord (callTracker, paramHash) {
	return callTracker.callRecord[paramHash]
}

function recordCall (callTracker, paramHash, previousCount) {
	callTracker.callRecord[paramHash] = { lastCall: Date.now(), count: previousCount + 1, error: null }
}

function recordError (callTracker, paramHash, err) {
	callTracker.callRecord[paramHash] = { lastCall: Date.now(), count: 0, error: err }
}

function call (callTracker, param) {
	const paramString = JSON.stringify(param)
	const paramHash = hash(paramString)
	if (wasCalled(callTracker, paramHash)) {
		const { lastCall, count, error } = getRecord(callTracker, paramHash)

		if (error) {
			log(`Not calling ${callTracker.apiCall.name} due to ${error}`)
		} else if (count >= callTracker.maxCount) {
			log(`Maximum reached for api call ${callTracker.apiCall.name} with param ${paramString}`)
		} else if (Date.now() - lastCall < callTracker.throttle) {
			log(`Calling ${callTracker.apiCall.name} too soon.`)
		} else {
			try {
				const result = callTracker.apiCall(param)
				recordCall(callTracker, paramHash, count)
				return result
			} catch (err) {
				recordError(callTracker, paramHash, err)
			}
		}
	} else {
		try {
			const result = callTracker.apiCall(param)
			recordCall(callTracker, paramHash, 0)
			return result
		} catch (err) {
			recordError(callTracker, paramHash, err)
		}
	}
}

function callLimited (apiCall, opt = { maxCount: 5, throttle: 10 }) {
	/// <summary>Returns a function wrapper, which will limit the number of calls to the interned function.</summary>
	/// <param name="apiCall" type="function">A function that takes a single object parameter and returns a value</param>
	/// <returns>A rate limited version of the interned function</returns>
	///	<remarks>
	///		Note: function name must be unique, or this will throw.
	///	</remarks>
	let tracker = null
	const apiCallNameHash = hash(apiCall.name)

	if (apiCallNameHash in CallTracker._trackers) {
		const msg = `Function ${apiCall.name} is already being rate limited.`
		log(msg)
		throw new Error(msg)
	} else {
		tracker = new CallTracker(apiCall, opt)
		CallTracker._trackers[hash(apiCall.name)] = tracker
	}

	return (param) => call(tracker, param)
}

export default callLimited
