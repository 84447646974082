import React from 'react';
import { Modal } from 'react-bootstrap';
import ContactUsForm from './contactUsForm';
import './contact-us-modal.scss';

function ContactUsModal(props) {

    const closeModal = () => props.onHide();

    return(
        <Modal
            {...props}
            size="lg"
            centered
            dialogClassName="contact-us-modal"
            >
            <Modal.Header closeButton>
                <div className="container-fluid">
                    <div className="row text-center">
                        <Modal.Title>
                            <h3>Let's Get Started</h3>
                            <p>Email us at contact@evervice.com or submit your request through our form:</p>
                        </Modal.Title>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <ContactUsForm closeModal={closeModal}/>
            </Modal.Body>
        </Modal>
    );
}

export default ContactUsModal;
