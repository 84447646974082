import React from 'react';
import './index.scss';
import PersonalConsult from '../../../assets/common/personal-consult.png';
import WorkCollab from '../../../assets/common/work-collab.png';
import ContdSupport from '../../../assets/common/contd-support.png';

function ProcessSection() {
    return(
        <div className="process-section">
            <div className='container-fluid mb-5'>
                <h2 className='text-center'><strong>OUR PROCESS</strong></h2>
                <div className='row my-3'>
                    <div className='col-6 col-md-3 text-right pr-5 my-auto'>
                        <img src={PersonalConsult} alt=''/>
                    </div>
                    <div className='col-6 col-md-3 my-auto'>
                        <h4 className='text-left'><strong>PERSONAL CONSULTATION</strong></h4>
                        <p className='text-left'>Once we receive your request, we will contact you to go through your needs to see what we can do for you and set expectations.</p>
                    </div>
                </div>
                <div className='d-flex flex-row-reverse my-3'>
                    <div className='col-6 col-md-3 text-left pl-5 my-auto'>
                        <img src={WorkCollab} alt=''/>
                    </div>
                    <div className='col-6 col-md-3 my-auto'>
                        <h4 className='text-right'><strong>WORKING COLLABORATION</strong></h4>
                        <p className='text-right'>As we work, we check in with you regularly to ensure you're getting exactly what you need.</p>
                    </div>
                </div>
                <div className='row my-3'>
                    <div className='col-6 col-md-3 text-right pr-5 my-auto'>
                        <img src={ContdSupport} alt=''/>
                    </div>
                    <div className='col-6 col-md-3 my-auto'>
                        <h4 className='text-left'><strong>CONTINUOUS SUPPORT</strong></h4>
                        <p className='text-left'>We won't leave you in the dark! Post-project, we're here to provide support for any questions or updates you want to make.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProcessSection;