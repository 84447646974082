import React, { Component } from 'react';
import './splash.scss';
import AssetContainer from './AssetContainer.js'


class TypeAnimation extends Component {

  constructor(props) {
    super(props);
    this.emails = ["A WEBSITE",
                    "A RESTAURANT",
                    "A COMMUNITY",
                    "A BRAND"];
    this.state = {
      animationState: 0,
      imageIterator: -1,
    };

    this.winState = {
      isMobile: window.innerWidth < 1200
    }
  }

  incrementImageItr = () => {
    this.setState((state) => {
      return {animationState: 1,
              imageIterator: state.imageIterator===this.emails.length-1 ? 0 : state.imageIterator + 1}
    })
  }

  renderCompanyName = () => {
    var bigTitle = <div></div>
    if (!this.winState.isMobile) {
      return bigTitle;
    }
    bigTitle = <div className="big-title"><h1>
    Evervice.
    </h1></div>;
    return bigTitle;
  }

  renderRotatingText = () => {
    var ReactRotatingText = require('react-rotating-text');
    if (!this.winState.isMobile) {
      return <div><h1> { "LET'S BUILD" } </h1>
              <h1>
              <ReactRotatingText items={ this.emails } pause="5000" deletingInterval="100"
                                   onTypingStart={() => this.setState(() => ({animationState: 0}))}
                                   onTypingEnd={() => this.incrementImageItr()}
                                   onDeletingStart={() => this.setState(() => ({animationState: 2}))}
                                   onDeletingEnd={() => this.setState(() => ({animationState: 3}))}/>
              </h1></div>;
    }

    return <div><h3> { "LET'S BUILD" } </h3>
            <h3>
            <ReactRotatingText items={ this.emails } pause="5000" deletingInterval="100"
                                 onTypingStart={() => this.setState(() => ({animationState: 0}))}
                                 onTypingEnd={() => this.incrementImageItr()}
                                 onDeletingStart={() => this.setState(() => ({animationState: 2}))}
                                 onDeletingEnd={() => this.setState(() => ({animationState: 3}))}/>
            </h3></div>;
  }

  render() {
    var mobileLabel = this.winState.isMobile ? "-mobile" : "";
    const companyName = this.renderCompanyName();
    const rotatingText = this.renderRotatingText();
    return (
      <div className={"row header-segment"+mobileLabel}>
        <div className={this.winState.isMobile ? "col-12" : "col-5" }>
          { companyName }
          <div className={ "type-segment" + mobileLabel}>
            <div className="title-text">
              { rotatingText }
            </div>
          </div>
        </div>
        <div className="col-6 page-segment d-none d-lg-block">
          <AssetContainer {...this.state}/>
        </div>
      </div>
    )
  }


}

export default TypeAnimation
