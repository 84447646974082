import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import image1 from '../../../assets/webCreatePage/carousel-sample-1.png';
import image2 from '../../../assets/webCreatePage/carousel-sample-2.png';
import image3 from '../../../assets/webCreatePage/carousel-sample-3.png';

function MyCarousel () {
    const carouselImgs=[image1, image2, image3];
    const carouselItems=[];
    carouselImgs.forEach(image => {
        carouselItems.push(
            <Carousel.Item>
                <img className="d-block w-100" src={image} alt="" />
            </Carousel.Item>
        )
    });
    
    return(
        <Carousel>
            {carouselItems}
        </Carousel>
    );
}

export default MyCarousel;