import React, { Component } from 'react'
import './body.scss';
import Splash from '../splash/splash.js';
import StartSection from '../../common/startSection';
import InfoSection from './infoSection/index.js'
import Nav from '../../common/navbar';
import Footer from '../../common/footer';


class MainBody extends Component {

  constructor(props) {
    super(props);
    this.infoPageRef = React.createRef();
    this.locationState = this.props.history.location.state;
  }

  componentDidMount() {
    if (this.locationState?.prevPath === "website-creation-page" ||
        this.locationState?.prevPath === "soft-dev-page") scrollToRef(this.infoPageRef);
  }

  render() {
    return (
      <div style={{width:'100%'}}>
        <Nav />
        <div className="splash-screen">
          <Splash state={this.state} />
        </div>
        <div className="info-screen" ref={this.infoPageRef}>
          <InfoSection />
        </div>
        <StartSection />
        <Footer />
      </div>
    )
  }
}

// General function for scroll to ref
const scrollToRef = (ref) => ref.current.scrollIntoView();

export default MainBody
