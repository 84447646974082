import React, { Component } from 'react'
import TypeAnimation from './TypeAnimation.js';
import './splash.scss';

class Splash extends Component {
  render() {
    return (
      <div>
        <TypeAnimation {...this.props}/>
      </div>
    )
  }
}

export default Splash;
