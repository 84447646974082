import React from 'react';

function Header() {
    return(
        <div className='container-fluid text-center py-5'>
            <h1>WEBSITE CREATION</h1>
        </div>
    );
}

export default Header;
