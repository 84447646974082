import React, { Component } from 'react';

import cafeImg_1 from '../../assets/splash/coffee/cafeImg_1.png';
import cafeImg_2 from '../../assets/splash/coffee/cafeImg_2.png';
import cafeText_1 from '../../assets/splash/coffee/cafeText_1.png';
import cafeText_2 from '../../assets/splash/coffee/cafeText_2.png';

import foodImg_1 from '../../assets/splash/food-place/foodImg_1.png';
import foodImg_2 from '../../assets/splash/food-place/foodImg_2.png';
import foodText_1 from '../../assets/splash/food-place/foodText_1.png';

import charityImg_1 from '../../assets/splash/community/charityImg_1.png';
import charityImg_2 from '../../assets/splash/community/charityImg_2.png';
import charityImg_3 from '../../assets/splash/community/charityImg_3.png';
import charityText_1 from '../../assets/splash/community/charityText_1.png';
import charityText_2 from '../../assets/splash/community/charityText_2.png';
// import charityTextBlob_2 from '../../assets/splash/community/charityTextBlob_1.png';

import corpImg_1 from '../../assets/splash/corp/corpImg_1.png';
import corpImg_2 from '../../assets/splash/corp/corpImg_2.png';
import corpText_1 from '../../assets/splash/corp/corpText_1.png';

import Frame from '../../assets/splash/frame.png';
import './animation.scss';


class AssetContainer extends Component
{
  render() {
    const cafe      = [new ImageComponent(cafeImg_1, "right", {x: "24%", y: "22%"}, "85%"),
                       new ImageComponent(cafeText_1, "left", {x: "62%", y: "22%"}, "60%"),
                       new ImageComponent(cafeImg_2, "left", {x: "5%", y: "6%"}, "70%"),
                       new ImageComponent(cafeText_2, "right", {x: "18%", y: "41%"}, "70%")];
    const foodPlace = [new ImageComponent(foodImg_1, "left", {x: "-10%", y: "24%"}, "50%"),
                       new ImageComponent(foodImg_2, "right", {x: "28%", y:"16%"}, "81%"),
                       new ImageComponent(foodText_1,"left" , {x: "10%", y:"34%"}, "50%")];
    const community = [new ImageComponent(charityImg_1, "right", {x: "46%", y: "15%"}, "53%"),
                       new ImageComponent(charityImg_2, "right", {x: "21%", y: "20%"}, "53%"),
                       new ImageComponent(charityImg_3, "left", {x: "13%", y: "30%"}, "70%"),
                       new ImageComponent(charityText_1, "left", {x: "30%", y: "21%"}, "50%"),
                       new ImageComponent(charityText_2, "right", {x: "60%", y: "48%"}, "50%")]
    const corp      = [new ImageComponent(corpImg_1, "left", {x: "-8%", y: "21%"}, "37%"),
                       new ImageComponent(corpImg_2, "right", {x: "44%", y: "10%"}, "60%"),
                       new ImageComponent(corpText_1, "left", {x: "59%", y: "29%"}, "69%"),];
    const animationRotation = [cafe, foodPlace, community, corp];

    if(this.props.imageIterator > -1)
    {
      var currentMockSite = animationRotation[this.props.imageIterator];
      var firstDivName = "image-component";
      var appearBlock = "imageAppear-";
      var disappearBlock = "imageDisappear-";
      const images = [];
      for(var itr = 0; itr < currentMockSite.length; itr++)
      {
        appearBlock = "imageAppear-";
        disappearBlock = "imageDisappear-";
        var image = currentMockSite[itr];
        if (this.props.animationState === 1)
        {
          firstDivName = "divAppear left image-component ";
          if(image.Location==="right")
          {
              firstDivName = "divAppear right image-component ";
          }
          appearBlock = appearBlock + image.Location;

          images.push(<div className={ firstDivName } style={{position:"absolute", top: image.Position.y, left: image.Position.x}}>
                        <img src={ image.ImagePath } alt=""
                        style={{width:image.PercentSize}}></img>
                      <div className={ appearBlock }></div>
                      </div>);
        }
        else if (this.props.animationState === 2) {
          firstDivName = "divDisappear left image-component";
          if(image.Location==="right")
          {
              firstDivName = "divDisappear right image-component ";
          }
          disappearBlock = disappearBlock + image.Location;
          images.push(<div className={ firstDivName } style={{position:"absolute", top: image.Position.y, left: image.Position.x}}>
                        <img src={ image.ImagePath } alt=""
                        style={{width:image.PercentSize}}></img>
                      <div className={ disappearBlock }></div>
                      </div>);
        }
      }

      return (
        <div className="mock-site-region">
            { images }
            <div style={{overflow: "hidden", position:"relative"}}>
              <div style={{verticalAlign:"middle"}}>
              <img src={ Frame } alt="frame" style={{width:"80%"}}></img>
              </div>
          </div>
        </div>
      )
    }
    return (
      <div></div>
    )
  }
}

class ImageComponent
{
  constructor(imagePath, location, pos, size)
  {
    this.ImagePath = imagePath;
    this.Location = location;
    this.Position = pos;
    this.PercentSize = size;
  }
}

export default AssetContainer;
