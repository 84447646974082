import React from 'react'
import './contact-us-form.scss'
import { Form, Col, Button } from 'react-bootstrap'
import TopRightArrow from '../../assets/common/topRightArrow.png'
import { FirebaseContext } from '../../infrastructure/Firebase'

function ContactUsForm (props) {
	const [validated, setValidated] = React.useState(false)
	const firstNameInput = React.useRef(null)
	const lastNameInput = React.useRef(null)
	const emailInput = React.useRef(null)
	// const dropdownInput = React.useRef(null)
	const messageInput = React.useRef(null)

	const handleSubmit = (firebase, event) => {
		const form = event.currentTarget
		const formResponse = {
			firstName: firstNameInput.current.value,
			lastName: lastNameInput.current.value,
			email: emailInput.current.value,
			message: messageInput.current.value
		}

		if (form.checkValidity()) {
			event.preventDefault(); event.stopPropagation()
			props.closeModal()
			console.log(formResponse)
			firebase.sendContactEmail(formResponse)
				.then(res => console.log(`Server responded: ${res}`))
				.catch(err => console.log(`Email not sent: ${err}`))
		} else {
			event.preventDefault(); event.stopPropagation()
		}

		setValidated(true)
	}

	return (
		<FirebaseContext.Consumer>{
			firebase => (
				<Form className="contact-us-form"
					noValidate
					validated={validated}
					onSubmit={(e) => handleSubmit(firebase, e)}>
					<Form.Row>
						<Form.Group as={Col} lg="4" xs="6" controlId="firstNameInput">
							<Form.Control
								ref={firstNameInput}
								required
								type="text"
								size="sm"
								placeholder="FIRST NAME"
								defaultValue=""
							/>
						</Form.Group>
						<Form.Group as={Col} lg="4" xs="6" controlId="lastNameInput">
							<Form.Control
								ref={lastNameInput}
								required
								type="text"
								size="sm"
								placeholder="LAST NAME"
								defaultValue=""
							/>
						</Form.Group>
						<Form.Group as={Col} lg="10" xs="12" controlId="emailInput">
							<Form.Control
								ref={emailInput}
								required
								type="email"
								size="sm"
								placeholder="EMAIL"
								defaultValue=""
							/>
						</Form.Group>
						{/* <Form.Group as={Col} lg="10" xs="12" controlId="dropdownInput">
							<Form.Control
								ref={dropdownInput}
								as="select"
								size="sm"
								custom
							>
								<option value="">HOW DID YOU HEAR ABOUT US?</option>
								<option>1</option>
								<option>2</option>
								<option>3</option>
								<option>4</option>
							</Form.Control>
						</Form.Group> */}
						<Form.Group as={Col} xs="12" controlId="messageInput">
							<Form.Control
								ref={messageInput}
								className="message"
								required
								type="text"
								as="textarea"
								size="sm"
								placeholder="HOW CAN WE HELP?"
								defaultValue=""
							/>
						</Form.Group>
						<Button className="submit-button hvr-grow mx-auto" type="submit">
						SUBMIT
							<span><img src={TopRightArrow} alt=''/></span>
						</Button>
					</Form.Row>
				</Form>)}
		</FirebaseContext.Consumer>
	)
}

export default ContactUsForm
