import React from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import throttle from 'lodash.throttle';
import HomePage from './pages/homePage'
import WebsiteCreationPage from './pages/websiteCreationPage';
import SoftDevPage from './pages/softDevPage';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Helmet from 'react-helmet'

class App extends React.Component {

  state = {
    isMobile: window.innerWidth < 600,
    bootstrapLarge: window.innerWidth >= 992,
    currentWidth: window.innerWidth
  }

  throttledHandleWindowResize = () => {
    return throttle(() => {
      if (window.innerWidth !== this.state.currentWidth) {
        this.setState({
          isMobile: window.innerWidth < 600,
          bootstrapLarge: window.innerWidth >= 992,
          currentWidth: window.innerWidth
        })
      }
    }, 500);
  }

  componentDidMount() {
    window.addEventListener('resize', this.throttledHandleWindowResize());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledHandleWindowResize());
  }

  render() {
    const AppRoutes = withRouter(({ location, history }) => {
      const animation = location.state?.backPage === true ? "back-page-transition" : "page-transition";
      return (
        <TransitionGroup
          childFactory={child => React.cloneElement(
            child,
            { classNames: animation, timeout: 500 }
          )}>
          <CSSTransition key={location.key}>
            <>
              <Helmet>
                <title>Evervice</title>
                <meta charSet='utf-8' />
                <meta name='description' content='Evervice - Website and software development consulting and contracting for any scale business' />
              </Helmet>
              <Switch location={location}>
                <Route exact path="/"
                  render={() => (<HomePage state={this.state} />)} />
                <Route exact path="/website-creation"
                  render={() => (<WebsiteCreationPage history={history} state={this.state} />)} />
                <Route exact path="/software-development"
                  render={() => (<SoftDevPage history={history} state={this.state} />)} />
              </Switch>
            </>
          </CSSTransition>
        </TransitionGroup>
      );
    });

    return (
      <div className="App">
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </div>
    );
  }
};

export default App;
