import React from 'react';
import Portfolio from '../../../assets/webCreatePage/portfolio.png';
import StandOut from '../../../assets/webCreatePage/stand-out.png';
import NoLimits from '../../../assets/webCreatePage/no-limits.png';
import Support from '../../../assets/webCreatePage/support.png';
import MyCarousel from './carousel';
import './index.scss';

function BenefitsSection(props) {

    const { isMobile, bootstrapLarge } = props.state;
    const images = [StandOut, NoLimits, Support]
    const cards = [];
    const cardObjs = [
        {title:'STAND OUT', text:"We'll help you build a website that's truly unique to you and your business."},
        {title:'NO LIMITS', text:"No more dealing with limitations that come with presets."},
        {title:'SUPPORT', text:"Not sure where to start? Figure out later you need to add shopping functions or analytics? We're here to support you."}
    ];
    const mobileCardView = (index, cardObj, myImg) => {
        return(
            <div className="row mx-auto" key={index}>
                <div className="col-5 text-center">
                    <img src={myImg} alt=""/>
                </div>
                <div className="col-7 my-auto">
                    <div className="text-left">
                        <h5>{cardObj.title}</h5>
                        <p>{cardObj.text}</p>
                    </div>
                </div>
            </div>
        );
    }
    const desktopCardView = (index, cardObj, myImg) => {
        return(
            <div className='col px-2 py-1' key={index}>
                <div className="card h-100">
                    <img className="card-img-top mx-auto" src={myImg} alt=""/>
                    <div className="card-body">
                        <h5 className="card-title">{cardObj.title}</h5>
                        <p className="card-text">{cardObj.text}</p>
                    </div>
                </div>
            </div>
        );
    }

    for (const [index, cardObj] of cardObjs.entries()) {
        cards.push(
            isMobile ? mobileCardView(index, cardObj, images[index]) : desktopCardView(index, cardObj, images[index])
        );
    }

    return(
        <div className="benefits-section">
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg my-auto'>
                        <div className="row">
                            <img className='carousel-outline' src={Portfolio} alt=""/>
                            <MyCarousel />
                        </div>
                    </div>
                    <div className='col-lg'>
                        <div className={ bootstrapLarge ? 'mt-3 text-left' : 'mt-3 text-center' }>
                            <h2><strong>THE BENEFITS</strong></h2>
                            <p>Whether you're building a portfolio or need a website for your business, we're here to help.</p>
                        </div>
                        <div className={ isMobile ? 'row my-3 mobile-view' : 'row my-3 mx-auto' }>
                            {cards}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BenefitsSection;
